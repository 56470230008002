import React from 'react'

import Layout from '../../components/layout'
import ServicesMenu from './_menu.js'

const GicleePage = () => (
  <Layout bgClass="services">
    <ServicesMenu pageName="giclee" />

    <div className="textPageDetails">
      <p>
        Nakatomi now offers high quality Gicleé printing with Epson Ultrachrome ink; an archival pigment-based ink that provides lush, deep colors. Standard paper is Coldpress Natural 305 gsm, an excellent watercolor paper. Other paper stocks available, minimum orders may apply.
      </p><p>
        There are no set-up fees for Gicleé printing, and pricing is by the square foot.
      </p><p>
        25 or more: $8.00 per square foot<br />
        5 to 24 prints: $12.00 per square foot<br />
        Less than 5 prints: $16.00 per square foot
      </p><p>
      <table className="simpleTable">
      <tr className="header"><td>print size</td><td>square footage</td><td>1 to 5 prints (each)</td><td>6 to 25 prints (each)</td><td>over 25 prints (each)</td></tr>
      <tr><td>8x10"</td><td>.56 sq'</td><td>$8.96</td><td>$6.72</td><td>$4.48</td></tr>
      <tr><td>9x12"</td><td>.75 sq'</td><td>$12.00</td><td>$9.00</td><td>$6.00</td></tr>
      <tr><td>12x24"</td><td>2 sq'</td><td>$32.00</td><td>$24.00</td><td>$16.00</td></tr>
      <tr><td>16x20"</td><td>2.66 sq'</td><td>$42.56</td><td>$31.92</td><td>$21.28</td></tr>
      <tr><td>18x24"</td><td>3 sq'</td><td> $48.00</td><td>$36.00</td><td>$24.00</td></tr>
      <tr><td>24x36"</td><td>6 sq'</td><td>$96.00</td><td>$72.00</td><td>$48.00</td></tr>
      </table>
      </p><p>
        Gicleé paper comes in 24” wide rolls. There is no minimum print size, and the maximum print size is 24”x36”. 24”x36” prints must have a 1” border all the way around the image, and so cannot be full bleed at that size.
      </p><p>
        Turnaround time depends on volume, but tends to be around 5-10 business days.
        Express printing available.
      </p><p>
        Gicleé images should be CMYK .tiff files saved with LZW compression, 600 dpi.
      </p>
    </div>
  </Layout>
)

export default GicleePage
